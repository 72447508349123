<template>
  <div v-if="getItemError">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        Ошибка при получении данных сотрудника
      </h4>
      <div class="alert-body">
        Не найден сотрудник с этим идентификатором. Можете перейти в
        <b-link
          class="alert-link"
          :to="{ name: 'employees'}"
        >
          Список сотрудников
        </b-link>
        и выбрать другого.
      </div>
    </b-alert>
  </div>
  <b-overlay
    v-else
    :show="loading"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col>
          <h4>
            {{ `${get(item, 'user.surname', '')} ${get(item, 'user.name', '')} ${get(item, 'user.patronymic', '')}` }}
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table-simple
            :striped="true"
            :bordered="false"
            :borderless="true"
            :outlined="false"
            :small="false"
            :hover="false"
            :dark="false"
            :fixed="false"
            :responsive="true"
            class="mt-1"
          >
            <b-tbody>
              <b-tr>
                <b-th>
                  ID
                </b-th>
                <b-td>
                  {{ item.id }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Добавлен
                </b-th>
                <b-td>
                  {{ item.created_at }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Изменен
                </b-th>
                <b-td>
                  {{ item.updated_at }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-th>
                  Роль
                </b-th>
                <b-td>
                  {{ activeRole.name }}
                  <div class="small text-secondary">
                    {{ activeRole.desc }}
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>

      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Разрешения</span>
          </b-card-title>
        </b-card-header>
        <b-table-simple
          :striped="false"
          :bordered="false"
          :borderless="false"
          :outlined="false"
          :small="false"
          :hover="false"
          :dark="false"
          :fixed="false"
          :responsive="true"
          class="m-0"
        >
          <b-tbody
            v-for="permissionCategory in groupedPermissions"
            :key="'c' + permissionCategory.id"
          >
            <b-tr>
              <b-th
                colspan="2"
                class="bg-light"
              >
                {{ permissionCategory.name }}
              </b-th>
            </b-tr>
            <b-tr
              v-for="permission in permissionCategory.permissions"
              :key="'p' + permission.id"
            >
              <b-td>
                <feather-icon
                  :icon="permission.total_value ? 'CheckIcon': 'MinusIcon'"
                  size="20"
                  :class="{
                    'text-success': permission.total_value,
                    'text-secondary': !permission.total_value,
                  }"
                />
              </b-td>
              <b-td>
                <div class="">
                  {{ permission.name }}
                </div>
                <div class="small text-secondary">
                  {{ permission.desc }}
                </div>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>

      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="BoxIcon"
              size="18"
            />
            <span class="align-middle ml-50">Доступ к проектам</span>
          </b-card-title>
        </b-card-header>

        <b-table-simple
          :striped="true"
          :bordered="false"
          :borderless="false"
          :outlined="false"
          :small="false"
          :hover="false"
          :dark="false"
          :fixed="false"
          :responsive="true"
          class="m-0"
        >
          <b-tbody>
            <b-tr
              v-for="itemProject in item.projects"
              :key="'p' + itemProject.project_id"
            >
              <b-td
                class="w-25"
              >
                <feather-icon
                  :icon="itemProject.value ? 'CheckIcon': 'MinusIcon'"
                  size="20"
                  :class="{
                    'text-success': itemProject.value,
                    'text-secondary': !itemProject.value,
                  }"
                />
              </b-td>
              <b-td>
                {{ itemProject.name !== 'Default' ? itemProject.name : $store.getters['workingMode/selected_company_name'] }}
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>

      <b-row
        v-if="activeRole.code !== 'super_admin' && $store.getters['workingMode/checkPermission']('manage_employees')"
        class="mt-2"
      >
        <b-col>
          <div class="d-flex flex-wrap">
            <b-button
              v-if="item.id"
              :to="{ name: 'employees.edit', params: { id: item.id } }"
              variant="primary"
            >
              Изменить
            </b-button>
            <b-button
              variant="outline-danger"
              class="ml-1"
              @click="deleteItem(item)"
            >
              Удалить
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BOverlay, BButton, BAlert, BLink, BBadge, BTabs, BTab,
  BFormGroup, BFormInput, BFormTextarea, BCardHeader, BCardTitle, BTable, BFormCheckbox,
  BTableSimple, BTbody, BTr, BTd, BTh, BListGroup, BListGroupItem, BThead,
} from 'bootstrap-vue'

import get from 'lodash/get'

import {
  getCurrentInstance, ref, onMounted, computed,
} from 'vue'
import useCrudShow from '@/composables/useCrudShow'
import { deleteWarehouse as deleteWarehouseApi } from '@/services/main-api/shop/warehouses'

import { getEmployee, deleteEmployee } from '@/services/main-api/business/employees'
import { getPermissions } from '@/services/main-api/business/permissions/permissions'
import { getPermissionCategories as getPermissionCategoriesApi } from '@/services/main-api/business/permissions/categories'
import { getRoles as getRolesApi } from '@/services/main-api/business/roles'
import { getProjects as getProjectsApi } from '@/services/main-api/business/projects'

export default {
  name: 'ShowItemView',
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BButton,
    BAlert,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BTableSimple,
    BTbody,
    BTr,
    BTd,
    BTh,
    BListGroup,
    BListGroupItem,
    BThead,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const getItemError = ref(false)

    // BEGIN permissions
    const permissions = ref([])
    const permissionCategories = ref([])

    const getAllPermissions = async () => {
      try {
        const res = await getPermissions({
          perPage: 1000,
          page: 1,
        })
        permissions.value = res.data.data
      } catch (e) {
        permissions.value = []
      }
    }

    const getPermissionCategories = async () => {
      try {
        const res = await getPermissionCategoriesApi({
          perPage: 1000,
          page: 1,
        })
        permissionCategories.value = res.data.data
      } catch (e) {
        permissionCategories.value = []
      }
    }
    // END permissions

    // BEGIN Roles
    const roles = ref([])

    const getRoles = async () => {
      try {
        const res = await getRolesApi({
          company: instance.$store.getters['workingMode/company_id'],
          perPage: 1000,
          page: 1,
        })
        roles.value = res.data.data
      } catch (e) {
        roles.value = []
      }
    }
    // END Roles

    // BEGIN Projects
    const projects = ref([])

    const getProjects = async () => {
      try {
        const res = await getProjectsApi({
          perPage: 1000,
          page: 1,
          search: `company_id:${instance.$store.getters['workingMode/company_id']}`,
        })

        projects.value = res.data.data
      } catch (e) {
        projects.value = []
      }
    }
    // END Projects

    const getItem = async () => {
      try {
        const res = await getEmployee(props.id)
        const tempItem = res.data.data

        await getProjects()

        tempItem.projects = tempItem.projects.map(ip => ({
          ...ip,
          name: get(projects.value.find(p => p.id === ip.project_id), 'name'),
        }))

        instance.item = tempItem
      } catch (e) {
        instance.item = {}
        instance.getItemError = true
      }
    }

    const {
      item,
      loading,
      deleteItem,
    } = useCrudShow({
      item: {
        id: null,
        user_id: '',
        user: {},
        role_id: '',
        projects: [],
        permissions: {},
      },
      getItem,
      deleteEntity: i => deleteEmployee(i.id),
      getQuestionTextBeforeDeletion: i => `Сотрудник <strong>${get(i, 'user.surname')} ${get(i, 'user.name')} ${get(i, 'user.patronymic')}</strong> будет удален`,
      getTextAfterDeletion: () => 'Сотрудник был удален.',
      runAfterDeletion: async () => {
        await instance.$router.go(-1)
      },
      getMoreData: async () => {
        await Promise.all([
          getRoles(),
          getAllPermissions(),
          getPermissionCategories(),
        ])
      },
    })

    const activeRole = computed(() => roles.value.find(r => r.id === item.value.role_id) || {})
    const activeRolePermissions = computed(() => get(activeRole.value, 'permissions', {}))

    const groupedPermissions = computed(() => permissionCategories.value.map(c => ({
      ...c,
      permissions: permissions.value.filter(p => p.permission_category_id === c.id).map(gp => ({
        ...gp,
        active_role_value: activeRolePermissions.value[gp.code],
        total_value: activeRolePermissions.value[gp.code] !== 2 ? activeRolePermissions.value[gp.code] : gp.value,
      })),
    })))

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      item,
      loading,

      getItemError,
      deleteItem,

      roles,
      activeRole,

      permissionCategories,
      groupedPermissions,

      projects,

      get,
    }
  },
}
</script>
